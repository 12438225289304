import React from 'react'
import css from './header.module.css'
import { Link } from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { OutboundLink } from "gatsby-plugin-google-analytics"

export const Header = () => {
    return (
        <div className={css.wrapper}>
            <Link to='/' className={css.logo}>Real <span className={css.real}>Not Complex</span></Link>
            <nav className={css.nav}>
                <div className={css.navGroup}>
                    <OutboundLink target="_blank" className={css.navLink} href="https://forms.gle/AAeaNdbp9w6GV8fC7">Add Resource</OutboundLink>
                    <AnchorLink className={css.navLink} to="/#about">About</AnchorLink>
                </div>
                <div className={css.navGroup}>
                </div>
            </nav>
        </div>
    );
}
