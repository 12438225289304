import React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { OutboundLink } from "gatsby-plugin-google-analytics"
import css from './footer.module.css';

export const Footer = () => (
    <footer className={css.footer}>
        <div>
        <p><span className={css.websiteName}>Real Not Complex</span></p>
        <p className={css.menuRow}>
            <AnchorLink to="/" className={css.newsletterLink}>Home</AnchorLink>
            <OutboundLink target="_blank" className={css.newsletterLink} href="https://forms.gle/AAeaNdbp9w6GV8fC7">Add Resource</OutboundLink>
            <AnchorLink className={css.newsletterLink} to="/#about">About</AnchorLink>
        </p>
        <p>© Real Not Complex {new Date().getFullYear()}</p>
        </div>
    </footer>
);